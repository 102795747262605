.card {
  width: 85%;
  min-height: 50%;
  background-color: #ffffff;
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 1em;
  box-sizing: content-box;
}

.card-small {
  flex: 2 1 0;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  box-sizing: border-box;
}

.card__heading {
  justify-content: stretch;
  line-height: normal;
  font-weight: 300;
  text-align: left;
  font-size: 1.3rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: 0.7em;
}

.card__heading.with-icon {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.card__heading.purple {
  border-bottom: 4px solid #4d46d9;
}

.card__heading.cyan {
  border-bottom: 4px solid #22b9da;
}

.card__heading.orange {
  border-bottom: 4px solid #f88d2a;
}

.card__heading h2 {
  margin-bottom: 0.2em;
  line-height: 1.5em;
  margin-top: 0.2em;
  font-size: 1.2em;
}

.card__content {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.card__content {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.card__content-description {
  font-weight: 300;
  margin-top: 0.3em;
  margin-bottom: 1em;
  font-size: 0.93em;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
}

.card__content.my-diversity-card {
  font-size: 0.85rem;
  line-height: 1.5rem;
}

.card__content.my-diversity-card p {
  margin: 0;
}

.card__content.communications-card {
  border-bottom: 1px solid #c3c3c3f2;
}

.card__content.communications-card > * {
  padding-bottom: 1rem;
  border-bottom: 1px solid #c3c3c3f2;
  flex: 1 1 100%;
}

.card__content.communications-card:last-of-type {
  border-bottom: none;
}

.card__content community-card fieldset {
  padding: 0;
}

.card div.card__content legend, .compound-heading {
  color: #282828;
}

hr {
  border: none;
  border-top: 1px solid #c3c3c3f2;
  width: 100%;
}

div.icas-radio-button {
  align-items: center;
  display: inline-block;
}

div.icas-radio-button input[type='radio'] {
  margin-right: 8px;
}


div#postal-radio__container {
  width: 100%;
  gap: 8px;
}

.card__content confirmation-card {
  font-size: 0.85rem;
  line-height: 1.5rem;
}


/* .card__content.community-card > hr {
  width: 95%;
} */

.card__content.contactinformation-card > legend,
.card__content.diversityquestion-card > legend {
  font-weight: 700;
  margin-bottom: 0.5em;
  padding-left: 0;
}

.card__content.membershipinformation-card,
.card__content.contactinformation-card,
.card__content.work-card {
  flex-flow: column;
  gap: 8px;
}

.card__content.personalinformation-card > *  {
  flex: 1 1 100%;
}
.card__content.work-card hr {
  color: #c3c3c3f2;
}

#transIdentity-explanation {
  justify-content: flex-start;
}

.work-card__inner, .work-card_inner > * { 
  flex: 1 1 100%;
}

label#companyEmployeeCount-label {
  text-wrap: wrap;
}

.membershipinformation-card__inner {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}

.membershipinformation-card__inner > *, .work-card__inner 
 {
  flex: 1 1 100%;
}

.card__content > *:last-child {
  border: none;
}

.card__footer {
  text-align: left;
  padding-left: 0.5em;
  padding-top: 0.5em;
}

.card__footer h3 {
  font-size: 1.05em;
  margin-bottom: 0;
  margin-top: 0;
}

.card__footer p {
  margin-top: 0.3em;
  font-size: 0.93em;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
}

.icas-toggle-switch__display-label {
  height: 0.8em;
}

.card__footer a {
  font-size: 0.85rem;
  transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.diversityquestion-card__inner {
  display: flex;
  flex-direction: column;
}

.icas-search-input__link {
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.85rem;
  display: inline-block;
  margin-right: auto;
  text-decoration-color: #4d46d9;
}


fieldset legend {
  margin-left: 0;
}

.icas-wrapper__inner.vertical label {
 font-size: 0.9rem;
  margin-bottom: 4px;
}

.card__content.communications-card .icas-toggle-switch .icas-checkbox:disabled + .icas-toggle-switch__label .icas-toggle-switch__inner {
  background-color: #ffffff;
  border-color: #282828;
}

.icas-toggle-switch .icas-checkbox:checked:disabled + .icas-toggle-switch__label .icas-toggle-switch__inner::before {
    transform: translateX(1.5em);
    background-color: #282828;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .card {
    width: 85%;
    padding: 1.5em;
    padding-top: 1em;
  }

  .card__content.community-card > * {
    width: 100%;
    padding-left: 0;
    margin-left: 0;
    margin-top: 0;
  }

  .card__content.personalinformation-card > * {
  flex: 1 0 30%;
  }

   .card__content.personalinformation-card.read-only {
    width: 50%;
   }

  div#postal-radio__container {
    width: 30%;
  }

  div.icas-radio-button {
    display: inline-flex;
  }

  div.icas-radio-button input[type='radio'] {
  margin-right: 0;
  }
  .membershipinformation-card__inner > * {
    flex: 0 0 45%;
  }

  .work-card__inner {
    flex: 1 1 30%;
  }



  .card__heading h2 {
    font-size: 1.5em;
    color: #282828;
  }

  .card__content.diversityquestion-card legend {
    margin-top: 0.7em;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .card__content {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    gap: 8px;
  }

  .card__content.diversityquestion-card {
    flex-direction: column;
    flex-flow: column;
  }

  .diversityquestion-card__inner > * {
    flex: 1 1 0;
  }

  .diversityquestion-card__inner .icas-textfield__outer.vertical label {
    margin-bottom: 0;
  }

  .card__content.work-card {
    flex-flow: row;
  }

  .compound-description {
    font-size: 0.85rem;
  }

  .icas-toggle-switch__container .compound-description {
    min-height: 4rem;
    max-height: 4rem;
  }

  .card__content.communications-card > :first-child {
  border-bottom: none;
  min-width: 250px;
}

  .card__content.communications-card {
    border-bottom: 0;
  }

  .card__content.communications-card > * {
    border-bottom: none;
    min-width: 250px;
  }

  .card__content.communications-card > *:first-child {
    border-bottom: none;
    min-width: 250px;
  }

  .card__content.personalinformation-card > * {
    flex: 1;
  }
}

@media (min-width: 1280px) {
  .card-small {
    width: 85%;
  }

  .card-small.left {
    margin-right: 1em;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1420px) {
  .card__content.communications-card > * {
    flex: 1 1 20%;
    border-bottom: none;
    min-width: 250px;
  }

  .card__content.communications-card > *:last-child {
    border-right: none;
  }
}
