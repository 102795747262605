.feedback__outer {
	position: fixed;
	width: 100%;
	height: 3em;
	top: 0;
	/* clear: both; */
	z-index: 999;
	text-align: center;
	padding-left: 1em;
	padding-right: 1em;
	left: 50%;
	transform: translateX(-50%);
}

.feedback {
	font-weight: 700;
	height: fit-content;
	line-height: 3em;
	text-align: initial;
	padding-left: 2em;
    padding-right: 2em;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	font-size: 0.9em;
}
.feedback-invalid {
	background-color: red;
	color: white;
}

.feedback-valid {
	background-color: green;
	color: white;
}

.feedback-saving {
	background-color: white;
	-webkit-box-shadow: 1px 2px 15px 2px rgb(137 137 137 / 57%);
	box-shadow: 1px 2px 15px 2px rgb(137 137 137 / 57%);
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	.feedback {
		height: 3em;
		line-height: 3em;
		text-align: center;
	}
}
