.app-container {
  background-color: transparent;
  border-radius: 5px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  min-height: 50%;
}

.container__menu {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}


.container__inner-small {
  display: flex;
  flex-direction: column;
  width: calc(85% + 3em);
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}

.details-container {
  background-color: #ffffff;
  border-radius: 5px;
  width: 65%;
  display: flex;
  flex-direction: column;
  padding-left: 3em;
  padding-right: 3em;
  padding-bottom: 1em;
  margin: auto;
}

.details-container hr {
  color: #dcdcdc;
  height: 1px;
  width: 100%;
}

.details-header {
  width: 100%;
  margin-bottom: 0.2em;
}

.details-header::after {
  background: #dcdcdc;
  content: '';
  height: 1px;
  width: 100%;
  display: block;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .app-container {
    width: 70%;
    padding: 1rem 1rem 3rem;
    margin: 2rem auto;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .container__inner-small {
    flex-direction: row;
  }
}
