.icas-address-container__link {
	text-decoration: underline;
	cursor: pointer;
	font-size: 0.9em;
}

.icas-address__outer {
	display: flex;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.icas-address__legend {
	margin-right: 0.5em;
	font-weight: 700;
	padding-left: 0;
}

.icas-address__read-only {
	display: flex;
	flex-direction: column;
	font-size: 0.9em;
	border-bottom: 1px solid #b3b1b2;
	width: 100%;
	line-height: 1.5;
	cursor: not-allowed;
}

@media (min-width: 768px) {
	.icas-address__read-only {
		width: 50%;
	}
}
