fieldset legend {
  margin-left: 0;
}

div#title-container {
  display: none;
}

#app-title {
  line-height: 1.75em;
  width: 70%;
  font-weight: 700;
  font-size: 2.25rem;
  margin-bottom: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  margin-right: auto;
  margin-left: calc(15.5% - 0.8em);
  color: #4d46d9;
}

button.menu-mobile-title {
  margin-top: 2em;
}

header#header.icas-header {
  height: 6rem;
}

a, span[role="button"] {
  text-decoration-color: #4d46d9;
  color: #282828;
}

a:active {
  color: #4d46d9;
}

select.icas-select {
  box-sizing: border-box;
}

a:active {
  color: #4d46d9;
}

select.icas-select {
  box-sizing: border-box;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  div#title-container {
    display: inline-flex;
    align-items: center;
    padding-top: 0;
    background-color: #ffffff;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
#app-title {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

}

