.save-bar__outer {
  width: calc(85% + 3em);
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-top: 1.5em;
}

.save-bar {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  padding: 0.8em;

}

.save-bar button {
  width: 10em;
}

/* // Extra small devices (portrait phones, less than 576px) */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .save-bar__outer {
    padding: 1.5em;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .save-bar__outer {
    justify-content: flex-end;
    width: 85%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
